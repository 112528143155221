import * as React from "react"
import {graphql} from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PageHeaderBlock from "../components/PageHeaderBlock";
import {Col, Container, Row} from "react-bootstrap";
import {StaticImage} from "gatsby-plugin-image";
import ArticleWidget from "../components/widgets/ArticleWidget";
import ArticleWidget2 from "../components/widgets/ArticleWidget2";

export default function TrebovaniyaKKartinkamNaSajteOtGoogleIYandeks() {
    const { t } = useTranslation();

    return (
        <Layout>
            <Seo title={t('post1Title')} description={t('post1Description')} />
            <PageHeaderBlock h1="post1H1" />
            <Container fluid className="px-flex">
                <Row className="my-3 my-xl-4 my-xxl-5">
                    <Col xxl={2} className="mb-2 mb-xl-3 mb-xxl-0 pb-1 pb-xl-0"><ArticleWidget /></Col>
                    <Col md={8} xxl={7} className="blog-post">
                        <StaticImage
                            src="../images/posts/post1/rekomendaczii-i-trebovaniya-k-kartinkam-na-sajte-ot-google-i-yandeks.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post1H1')}
                            className="blog-post__img w-100 mb-2 mb-xl-3 mb-xxl-4"
                            placeholder="blurred"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post1Text')}} />
                        <Row>
                            <Col xs={10} className="d-flex justify-content-center align-items-center">
                                <ul dangerouslySetInnerHTML={{__html: t('post1Text1')}} />
                            </Col>
                            <Col xs={2}>
                                <StaticImage
                                    src="../images/posts/post1/Rekomendaczii-i-trebovaniya-k-kartinkam-na-sajte-ot-google-i-yandeks.png"
                                    width={650}
                                    quality={95}
                                    formats={["auto", "webp", "avif"]}
                                    alt={t('post1Text1ImgAlt')}
                                    title={t('post1Text1ImgTitle')}
                                    className="w-100"
                                />
                            </Col>
                        </Row>
                        <div dangerouslySetInnerHTML={{__html: t('post1Text2')}} />
                        <StaticImage
                            src="../images/posts/post1/Pravilnoe-nazvanie-izobrazheniya-zagruzhaemogo-na-sajt.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post1Text2ImgAlt')}
                            title={t('post1Text2ImgTitle')}
                            className="w-100"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post1Text3')}} />
                        <StaticImage
                            src="../images/posts/post1/Ukazyvat-li-razmery-kartinok.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post1Text3ImgAlt')}
                            title={t('post1Text3ImgTitle')}
                            className="w-100"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post1Text4')}} />
                        <StaticImage
                            src="../images/posts/post1/Rekomendaczii-po-alt-dlya-kartinok-sajta.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post1Text4ImgAlt')}
                            title={t('post1Text4ImgTitle')}
                            className="w-100"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post1Text5')}} />
                        <StaticImage
                            src="../images/posts/post1/optimizacziya-izobrazhenij.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post1Text5ImgAlt')}
                            title={t('post1Text5ImgTitle')}
                            className="w-100"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post1Text6')}} />
                        <StaticImage
                            src="../images/posts/post1/optimalnoe-kolichestvo-izobrazhenij-na-statyu.jpg"
                            width={2560}
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            alt={t('post1Text6ImgAlt')}
                            title={t('post1Text6ImgTitle')}
                            className="w-100"
                        />
                        <div dangerouslySetInnerHTML={{__html: t('post1Text7')}} />
                    </Col>
                    <Col md={4} xxl={3}><ArticleWidget2 /></Col>
                </Row>
            </Container>
        </Layout>
    )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;